<div class="wrapper" *ngIf="currLang">

	<div class="langs" [class.collapsed]="collapsed" (click)="onOpenPicker()">

		<div
			class="lang"
			*ngFor="let lang of langs"
			[class.selected]="!collapsed && lang.id === currLang.id"
			(click)="onUpdateLang(lang.id)"
		>

			<shared-img [name]="lang.id" size="18"></shared-img>

			<span class="name">{{ lang.shortName }}</span>

			<i
				class="fal fa-chevron-down"
				[class.hidden]="lang.id !== currLang.id"
				[class.rotated]="!collapsed"
			></i>

		</div>

	</div>

</div>
