import {Component, OnDestroy} from '@angular/core'
import {Store} from '@ngxs/store'
import {Subscription} from 'rxjs'
import * as R from 'ramda'

import {AppState} from '@web/core/states/app/app.state'
import {LangType} from '@web/core/types/api/lang.type'
import {UpdateLang} from '@web/core/states/app/actions'
import {LangModel, LANGS} from '@web/core/models/local/lang.model'

@Component({
  selector: 'shared-lang-toggler',
  templateUrl: './lang-toggler.component.html',
  styleUrls: ['./lang-toggler.component.scss'],
})
export class LangTogglerComponent implements OnDestroy {

  langs: LangModel[]

  currLang: LangModel

  langSubscription: Subscription

  collapsed = true

  constructor(
    private store: Store,
  ) {
    this.langSubscription = this.store.select(AppState.lang)
      .subscribe(lang => {
        const findCurrLang = l => l.id === lang

        this.currLang = R.find(findCurrLang, LANGS)
        this.langs = [this.currLang, ...R.reject(findCurrLang, LANGS)]
      })
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe()
    }
  }

  onOpenPicker(): void {
    this.collapsed = !this.collapsed
  }

  onUpdateLang(lang: LangType): void {
    this.store.dispatch(new UpdateLang(lang))
  }
}
